import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar'

const CataloguePage = () => (
  <Layout>
    <SEO title="Catalogue" />
    <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <h1 class="text-2xl font-bold mb-8 md:mt-10 divider">Notre catalogue en ligne</h1>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div class="grid grid-cols-1 gap-8 md:col-span-3 md:my-5 mx-3"> {/** first element */}
         <p>Découvrez sans plus tarder notre catalogue 2017 dans lequel vous trouverez la totalité des produits Corsaf design et d’autres encore…vous y perdrez la tête ! N’attendez plus, allez vite voir …</p>
    </div>
      <div>
        <Sidebar />
      </div>
    </div>
  </Layout>

)

export default CataloguePage